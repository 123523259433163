import { Box, H1, Typography } from "@vp/swan";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { GroupsAndTeamsContext } from "../../context/GroupsAndTeamsContext";
import { shouldAllowUploadFlow } from "../../../../utils/uploadTeamMemberHelper";

export const TeamDetailsTitle: React.FC<{}> = () => {
  const { t } = useTranslation("translation");
  const { isYsdDesign } = useContext(GroupsAndTeamsContext);
  const shouldRenderUploadButton = shouldAllowUploadFlow();

  return (
    <Box className="teams-header-title">
      <H1 fontSkin="title-headline">
        {isYsdDesign
          ? t("groups-and-teams.team-details-title")
          : t("groups-and-teams.add-personalization-title")}
      </H1>
      <Typography fontSkin="body-standard">
        {isYsdDesign
          ? shouldRenderUploadButton
            ? t("groups-and-teams.add-team-personalization-description")
            : t("groups-and-teams.team-details-description")
          : shouldRenderUploadButton
            ? t("groups-and-teams.add-personalization-description")
            : t("groups-and-teams.group-details-description")}
      </Typography>
      <Typography fontSkin="body-standard">
        {`*${t("groups-and-teams.required-description")}`}
      </Typography>
    </Box>
  );
};
