import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button, Icon } from "@vp/swan";

import { GroupFormContext } from "../../context/GroupFormContext";
import { GroupsAndTeamsContext } from "../../../context/GroupsAndTeamsContext";
import { useAlertState } from "../../../../root/contexts/AlertContext";

interface IAddMemberButtonProps {
  shouldRenderCTAAsColumn: boolean;
}

const AddMemberButton: React.FC<IAddMemberButtonProps> = ({
  shouldRenderCTAAsColumn,
}) => {
  const { t } = useTranslation("translation");
  const { clearAlerts } = useAlertState();
  const { setValidity, formValidity, setMemberFormsToValidate } =
    useContext(GroupFormContext);
  const {
    groupsAndTeamsMembers,
    updateGroupsAndTeamsMembers,
    isUpdatingTeam,
    getBlankTeamMember,
    isYsdDesign,
    saveGroupsAndTeamsConfig,
  } = useContext(GroupsAndTeamsContext);

  const onClickAddMember = async () => {
    const currentMember =
      groupsAndTeamsMembers[groupsAndTeamsMembers.length - 1];

    if (!formValidity[currentMember.id]) {
      setMemberFormsToValidate([currentMember.id]);
      // move the end of form into view to see the error message
      const errorForm = document.getElementById(currentMember.id);
      if (errorForm) {
        errorForm.focus({ preventScroll: true });
        errorForm.scrollIntoView({
          block: "end",
          inline: "end",
          behavior: "smooth",
        });
      }
      return;
    }

    clearAlerts();
    await saveGroupsAndTeamsConfig();

    const blankTeamMember = getBlankTeamMember();
    const newTeamMembers = [...groupsAndTeamsMembers, blankTeamMember];
    setValidity(blankTeamMember.id, false);
    updateGroupsAndTeamsMembers(newTeamMembers);
    setMemberFormsToValidate([]);
  };
  return (
    <Button
      skin="primary"
      iconPosition="left"
      mr={shouldRenderCTAAsColumn ? 0 : "to-actions"}
      onClick={onClickAddMember}
      disabled={isUpdatingTeam}
    >
      <Icon iconType="plus" />
      {isYsdDesign
        ? t("groups-and-teams.add-teammate")
        : t("groups-and-teams.add-person")}
    </Button>
  );
};

export default AddMemberButton;
