import React, { useRef, useLayoutEffect, useContext } from "react";
import {
  BoundedContent,
  Box,
  Divider,
  FlexBox,
  useScreenClass,
} from "@vp/swan";
import { ConnectedGroupsAndTeamsForm } from "./connectedGroupsAndTeamsForm";
import { GroupsAndTeamsContext } from "../context/GroupsAndTeamsContext";
import { TeamDetailsTitle } from "./title/TeamDetailsTitle";
import { GroupFormContext } from "./context/GroupFormContext";
import AddMemberButton from "./connectedGroupsAndTeamsForm/buttons/AddMemberButton";
import PreviewDesignsLink from "./connectedGroupsAndTeamsForm/buttons/PreviewDesignsLink";
import AddNonPersonalizedLink from "./buttons/AddNonPersonalizedLink";
import AlertCollection from "../../root/AlertCollection";
import { UploadButton } from "./connectedGroupsAndTeamsForm/buttons/UploadButton";
import { shouldAllowUploadFlow } from "../../../utils/uploadTeamMemberHelper";

export const TeamDetails: React.FC<{}> = () => {
  const formEntriesScrollRef = useRef<HTMLDivElement>(null);
  const {
    currentFocusEntryId,
    isYsdDesign,
    isNonPersonalizedAllowed,
    teamsPlaceholders,
    availableSizes,
  } = useContext(GroupsAndTeamsContext);
  const { areFormFieldsInitialized } = useContext(GroupFormContext);
  const isMobileView = useScreenClass() === "xs";
  const hasNonPersonalizedButton = isYsdDesign || isNonPersonalizedAllowed;

  // Styling variables
  const hasFewPlaceholders =
    teamsPlaceholders.length + (availableSizes.length > 1 ? 1 : 0) + 1 <= 2;
  const shouldRenderCTAAsColumn = isMobileView || hasFewPlaceholders;
  const shouldRenderUploadButton = shouldAllowUploadFlow();

  const scrollToForm = (formId: string) => {
    const currentForm = document.getElementById(formId);
    if (currentForm) {
      currentForm.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
    }
  };

  useLayoutEffect(() => {
    scrollToForm(currentFocusEntryId);
  }, [currentFocusEntryId]);

  return (
    <BoundedContent
      paddingTop={5}
      paddingX={5}
      className="teams-details-container"
      data-testid="teams-selector"
      ref={formEntriesScrollRef}
    >
      <TeamDetailsTitle />
      {shouldRenderUploadButton && (
        <>
          <Box my="to-actions">
            <UploadButton />
          </Box>
          <AlertCollection />
          <Divider />
        </>
      )}
      <ConnectedGroupsAndTeamsForm />
      {areFormFieldsInitialized && (
        <>
          <FlexBox
            alignItems={shouldRenderCTAAsColumn ? "flex-start" : "center"}
            flexDirection={shouldRenderCTAAsColumn ? "column" : "row"}
            justifyContent="flex-start"
            mb="between-subsections"
            paddingX={0}
            gap={shouldRenderCTAAsColumn ? "to-actions" : 0}
          >
            <AddMemberButton
              shouldRenderCTAAsColumn={shouldRenderCTAAsColumn}
            />
            {hasNonPersonalizedButton && (
              <AddNonPersonalizedLink
                shouldRenderCTAAsColumn={shouldRenderCTAAsColumn}
              />
            )}
            <PreviewDesignsLink />
          </FlexBox>
        </>
      )}
    </BoundedContent>
  );
};
