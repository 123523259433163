import React, { useEffect, useState } from "react";
import {
  FormField,
  FormInputGroup,
  Dropdown,
  DropdownOption,
  Typography,
  DropdownFloatingLabel,
  DropdownWithFloatingLabel,
  VisuallyHidden,
} from "@vp/swan";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "./ErrorMessage";
import type { FieldDefinition } from "../../../../../types/GroupsAndTeams";
import type { SizeData } from "../../../../../types/TeamDetailsConfig";
import type { InventoryData } from "../../../../../types/Inventory";
import { ERROR_TYPE, NO_INPUT_SIZE } from "../../../../../commons/constants";
import {
  findSizeValueInAvailableSizes,
  validateSizeValue,
} from "../../../../../utils/validationHelpers";

export type SizeDropdownFieldProps = {
  fieldDefinition: FieldDefinition;
  defaultValue?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onDropdownChange: (value: string, key: string, isValid: boolean) => void;
  availableSizes: SizeData[];
  inventory: Record<string, InventoryData> | undefined;
};

export const SizeDropdownField: React.FC<SizeDropdownFieldProps> = ({
  fieldDefinition,
  defaultValue,
  disabled = false,
  autoFocus,
  onDropdownChange,
  availableSizes,
  inventory,
}) => {
  const [selectedSize, setSelectedSize] = useState(
    defaultValue || NO_INPUT_SIZE,
  );
  const [errorType, setErrorType] = useState(ERROR_TYPE.NONE);
  const { t } = useTranslation("translation");

  useEffect(() => {
    // Initialize dropdown field
    if (defaultValue) {
      const isInputValid = validateSizeValue(
        availableSizes,
        inventory ?? {},
        defaultValue,
      );
      const isInputInAvailableSizes = findSizeValueInAvailableSizes(
        availableSizes,
        defaultValue,
      );

      if (!isInputValid) {
        setErrorType(ERROR_TYPE.INVALID);
      }

      if (defaultValue !== selectedSize) setSelectedSize(defaultValue);
      else if (!isInputValid && !isInputInAvailableSizes) {
        setErrorType(ERROR_TYPE.NONE);
      }

      onDropdownChange(defaultValue, fieldDefinition.key, isInputValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !findSizeValueInAvailableSizes(availableSizes, selectedSize) &&
      selectedSize !== NO_INPUT_SIZE
    ) {
      setErrorType(ERROR_TYPE.SIZE_INVALID);
    }
    if (
      findSizeValueInAvailableSizes(availableSizes, selectedSize) &&
      inventory &&
      defaultValue &&
      (!inventory[selectedSize] || inventory[selectedSize].numAvailable === 0)
    )
      setErrorType(ERROR_TYPE.SIZE_UNAVAILABLE);
    if (defaultValue === selectedSize && selectedSize === NO_INPUT_SIZE)
      setErrorType(ERROR_TYPE.SIZE_UNSPECIFIED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, selectedSize]);

  const handleSizeChange = (curSize: string) => {
    setSelectedSize(curSize);
    setErrorType(ERROR_TYPE.NONE);
    onDropdownChange(curSize, fieldDefinition.key, true);
  };

  return (
    <FormField
      className="teams-details-form-field teams-details-size-field"
      marginRight={{ sm: "between-actions" }}
    >
      <FormInputGroup>
        <DropdownWithFloatingLabel
          style={{
            width: "100%",
          }}
        >
          <Dropdown
            fullWidth
            autoFocus={autoFocus}
            name={fieldDefinition.key}
            aria-required={"true"}
            value={selectedSize}
            onChange={(event: any) => handleSizeChange(event.target.value)}
            disabled={disabled}
          >
            <DropdownOption disabled value={NO_INPUT_SIZE}>
              {t("form-placeholder.dropdown-choose")}
            </DropdownOption>
            {!findSizeValueInAvailableSizes(availableSizes, selectedSize) &&
              selectedSize !== NO_INPUT_SIZE && (
                <DropdownOption disabled value={selectedSize}>
                  {selectedSize}
                </DropdownOption>
              )}
            {availableSizes.map((size) => {
              const isOptionDisabled =
                !!inventory && !inventory[size.key].numAvailable;
              return (
                <DropdownOption
                  disabled={isOptionDisabled}
                  key={size.key}
                  value={size.key}
                >
                  {isOptionDisabled
                    ? `${size.name} - ${t("groups-and-teams.size-out-of-stock")}`
                    : size.name}
                </DropdownOption>
              );
            })}
          </Dropdown>
          <DropdownFloatingLabel>
            {`${fieldDefinition.name}`}
            <span aria-hidden="true">*</span>
            <VisuallyHidden>Required</VisuallyHidden>
          </DropdownFloatingLabel>
        </DropdownWithFloatingLabel>
        <Typography noWrap>
          {errorType !== ERROR_TYPE.NONE && <ErrorMessage type={errorType} />}
        </Typography>
      </FormInputGroup>
    </FormField>
  );
};
