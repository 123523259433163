import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Spinner, FlexBox, useScreenClass } from "@vp/swan";
import {
  GroupsAndTeamsContext,
  QUANTITY_KEY,
  SIZE_KEY,
} from "../../context/GroupsAndTeamsContext";
import { FormEntryPanel } from "./FormEntryPanel";
import { GroupFormContext } from "../context/GroupFormContext";
import { useBffData, useMerchantAvailability } from "../../../../hooks/queries";
import type {
  FieldDefinition,
  FieldType,
  GroupsAndTeamsMember,
  Placeholder,
} from "../../../../types/GroupsAndTeams";
import {
  getRemainingStock,
  getSameSizeGroupsAndTeamsMembers,
  validatePlaceholderValue,
  validateQuantityValue,
  validateSizeValue,
} from "../../../../utils/validationHelpers";
import {
  HARD_GOOD_INVENTORY_KEY,
  INFINITE_INVENTORY,
} from "../../../../commons/constants";

export const ConnectedGroupsAndTeamsForm: React.FC<{}> = () => {
  const { t } = useTranslation("translation");
  const { data: bffData, isFetched: bffDataIsFetched } = useBffData();
  const { isSizedGood } = bffData!;
  const isMobileView = useScreenClass() === "xs";

  const { data: inventory, isFetched: inventoryIsFetched } =
    useMerchantAvailability();

  const {
    quantitySelectionListValues,
    groupsAndTeamsMembers,
    areTeamMembersInitialized,
    currentFocusEntryId,
    setCurrentFocusEntryId,
    updateGroupsAndTeamsMembers,
    teamsPlaceholders,
    availableSizes,
  } = useContext(GroupsAndTeamsContext);
  const {
    setValidity,
    memberFormsToValidate,
    areFormFieldsInitialized,
    setAreFormFieldsInitialized,
  } = useContext(GroupFormContext);
  const [formFieldData, setFormFieldData] = useState([] as FieldDefinition[]);

  const isLoading =
    !bffDataIsFetched || !inventoryIsFetched || !areTeamMembersInitialized;

  useEffect(() => {
    if (!isLoading && teamsPlaceholders.length) {
      initializeGroupForm(teamsPlaceholders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, teamsPlaceholders]);

  const handleOnFocus = (selectedFocusEntryId: string) => {
    if (selectedFocusEntryId !== currentFocusEntryId)
      setCurrentFocusEntryId(selectedFocusEntryId);
  };

  const initializeGroupForm = (placeholders: Placeholder[]) => {
    const formFields: FieldDefinition[] = placeholders.map(
      (placeholder: Placeholder) => {
        // For now, the agreement with YSD is to only consider the first element of the validation array
        let validationPattern =
          placeholder.validations &&
          placeholder.validations[0]?.type === "regex"
            ? placeholder.validations[0].value
            : "";

        // Temporary fix: hard code Number field regex to only accept only numeric values, to be removed once YSD fixes the regex
        if (placeholder.type === "number" && placeholder.key !== QUANTITY_KEY)
          validationPattern = "^[0-9]{0,2}$";

        const decorationLocations = placeholder.decorationLocations as string[];

        let fieldType: FieldType;
        if (placeholder.type === "number") fieldType = "LegacyNumber";
        else if (placeholder.type === "text") fieldType = "LegacyText";
        else fieldType = placeholder.type as FieldType;

        return {
          key: placeholder.key,
          name: placeholder.name,
          type: fieldType,
          decorationLocations: decorationLocations,
          validationPattern,
        };
      },
    );

    if (isSizedGood) {
      formFields.push({
        key: SIZE_KEY,
        name: t("groups-and-teams.size-label"),
        validationPattern: "",
      });
    }

    formFields.push({
      key: QUANTITY_KEY,
      name: t("groups-and-teams.quantity-label"),
      type: "Number",
      validationPattern: "^[1-9][0-9]*$",
    });

    // set initial validity to false
    groupsAndTeamsMembers.forEach((member) => {
      setValidity(member.id, false);
    });

    setFormFieldData(formFields);
    setAreFormFieldsInitialized(true);
  };

  const handleFormEntryChange = (
    entryId: string,
    formData: Record<string, string>,
    isFormValid: boolean,
  ) => {
    const currGroupMembers = groupsAndTeamsMembers;
    const updatedGroupMembers = currGroupMembers.map(
      (groupMember: GroupsAndTeamsMember) => {
        if (groupMember.id === entryId) {
          const placeholderValues = {} as Record<string, string>;

          Object.keys(formData).forEach((key) => {
            if (key !== QUANTITY_KEY && key !== SIZE_KEY) {
              placeholderValues[key] = formData[key];
            }
          });

          const updatedGroupMember: GroupsAndTeamsMember = {
            id: entryId,
            qty: parseInt(formData[QUANTITY_KEY]) || 0,
            selectedOptions: isSizedGood ? { Size: formData.size } : {},
            isNonPersonalized: groupMember.isNonPersonalized,
            shouldShowWarningForEmptyFields:
              groupMember.shouldShowWarningForEmptyFields,
            placeholderValues,
            ...(groupMember.documentUrl && {
              documentUrl: groupMember.documentUrl,
            }),
            ...(groupMember.previewUrl && {
              previewUrl: groupMember.previewUrl,
            }),
            ...(groupMember.previewUrls && {
              previewUrls: groupMember.previewUrls,
            }),
          };

          return updatedGroupMember;
        } else {
          return groupMember;
        }
      },
    );

    updateGroupsAndTeamsMembers(updatedGroupMembers);
    setValidity(entryId, isFormValid);
  };

  const getInitialTeammateValues = (entry: GroupsAndTeamsMember) => {
    let initialTeammateValues = {};
    formFieldData.forEach((field: FieldDefinition) => {
      let initialValue;
      if (field.key === QUANTITY_KEY) {
        initialValue =
          entry[QUANTITY_KEY] === 0 ? "" : entry[QUANTITY_KEY].toString();
      } else if (field.key === SIZE_KEY) {
        initialValue = entry.selectedOptions["Size"] || "";
      } else {
        initialValue = entry.placeholderValues[field.key];
      }
      initialTeammateValues = {
        [field.key]: initialValue,
        ...initialTeammateValues,
      };
    });

    return initialTeammateValues;
  };

  const getInitialTeammateValidity = (
    formFieldData: FieldDefinition[],
    groupsAndTeamsMember: GroupsAndTeamsMember,
  ) => {
    const initialTeammateValidity = {} as Record<string, boolean>;

    formFieldData.forEach((fieldDefinition) => {
      let initialValidityValue = false;

      // When the teammembers are added via upload, we can't assume the validity is false -- we have
      // to manually check it.
      switch (fieldDefinition.key) {
        case QUANTITY_KEY:
          if (groupsAndTeamsMember.qty) {
            // Other teammates is either all other teammates (Hard Goods/One Sized Goods) or all other teammates with the
            // same size
            const selectedSize = groupsAndTeamsMember.selectedOptions["Size"];
            const otherTeammates = getSameSizeGroupsAndTeamsMembers(
              groupsAndTeamsMembers,
              groupsAndTeamsMember.id,
              selectedSize,
            );

            // Because size & quantity are intrinsically linked, we need to verify that the specified size actually exists
            const totalStockIndex = isSizedGood
              ? selectedSize
              : HARD_GOOD_INVENTORY_KEY;
            if (inventory && inventory[totalStockIndex]) {
              const remainingStock = getRemainingStock(
                otherTeammates,
                inventory[totalStockIndex].numAvailable,
              );

              initialValidityValue = validateQuantityValue(
                quantitySelectionListValues,
                fieldDefinition.validationPattern,
                groupsAndTeamsMember.qty.toString(),
                Math.min(remainingStock, INFINITE_INVENTORY),
              );
            }
          }
          break;
        case SIZE_KEY:
          if (groupsAndTeamsMember.selectedOptions["Size"])
            initialValidityValue = validateSizeValue(
              availableSizes,
              inventory ?? {},
              groupsAndTeamsMember.selectedOptions["Size"],
            );
          break;
        default:
          if (
            groupsAndTeamsMember.placeholderValues[fieldDefinition.key] ||
            groupsAndTeamsMember.placeholderValues[fieldDefinition.key] === ""
          ) {
            initialValidityValue = validatePlaceholderValue(
              groupsAndTeamsMember.placeholderValues[fieldDefinition.key],
              fieldDefinition.validationPattern,
            );
          }
          break;
      }

      initialTeammateValidity[fieldDefinition.key] = initialValidityValue;
    });

    return initialTeammateValidity;
  };

  return (
    <>
      {areFormFieldsInitialized ? (
        <FlexBox
          flexDirection={isMobileView ? "row" : "column"}
          className="teams-details-form-container"
          mt="between-sections"
          mb="to-actions"
        >
          {groupsAndTeamsMembers.map(
            (entry: GroupsAndTeamsMember, i: number) => {
              return (
                <FormEntryPanel
                  key={entry.id}
                  formFieldDefinitions={formFieldData}
                  initialEntryValidity={getInitialTeammateValidity(
                    formFieldData,
                    entry,
                  )}
                  initialEntryData={getInitialTeammateValues(entry)}
                  entryId={entry.id}
                  entryNumber={i}
                  hasAddMemberButton={i + 1 === groupsAndTeamsMembers.length}
                  onFocus={() => handleOnFocus(entry.id)}
                  onFormEntryChanged={(entryId, formData, isFormValid) =>
                    handleFormEntryChange(entryId, formData, isFormValid)
                  }
                  validateQuantityInput={memberFormsToValidate.includes(
                    entry.id,
                  )}
                  isSizedGood={isSizedGood}
                  availableSizes={availableSizes}
                  inventory={inventory}
                  isNonPersonalizedPlaceholder={entry.isNonPersonalized}
                  shouldShowWarningForEmptyFields={
                    entry.shouldShowWarningForEmptyFields
                  }
                />
              );
            },
          )}
        </FlexBox>
      ) : (
        <FlexBox className="teams-details-form-container">
          <Spinner
            accessibleText={t("loading-label")}
            marginX="auto"
            showText
          />
        </FlexBox>
      )}
    </>
  );
};
