import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormField,
  FormInputGroup,
  Dropdown,
  DropdownOption,
  FormHelper,
  FlexBox,
  DropdownFloatingLabel,
  DropdownWithFloatingLabel,
  VisuallyHidden,
} from "@vp/swan";
import type { FieldDefinition } from "../../../../../../types/GroupsAndTeams";
import {
  ERROR_TYPE,
  NO_INPUT_QUANTITY,
  NO_INPUT_SIZE,
} from "../../../../../../commons/constants";
import { ErrorMessage } from "../ErrorMessage";
import { GroupsAndTeamsContext } from "../../../../context/GroupsAndTeamsContext";
import { validateQuantityValue } from "../../../../../../utils/validationHelpers";
import { useExcelUpload } from "../../../../../root/contexts/ExcelUploadContext";
import { GroupFormContext } from "../../../context/GroupFormContext";

export type QuantityDropdownFieldProps = {
  autoFocus?: boolean;
  fieldDefinition: FieldDefinition;
  presetValue?: string;
  disabled?: boolean;
  onDropdownChange: (value: string, key: string, isValid: boolean) => void;
  triggerValidation: boolean;
  entryId: string;
  updateTeammateValidity: (key: string, validityData: boolean) => void;
  isSizedGood: boolean;
  sizeName?: string;
  totalStock: number;
  isLowStock: boolean;
  dropdownValues: Array<string>;
  handleSizeDropdownError: () => void;
};

export const QuantityDropdownField: React.FC<QuantityDropdownFieldProps> = ({
  autoFocus,
  fieldDefinition,
  presetValue,
  disabled = false,
  onDropdownChange,
  triggerValidation,
  entryId,
  updateTeammateValidity,
  isSizedGood,
  sizeName,
  totalStock,
  isLowStock,
  dropdownValues,
  handleSizeDropdownError,
}) => {
  const { t } = useTranslation("translation");
  const { groupsAndTeamsMembers } = useContext(GroupsAndTeamsContext);
  const { memberFormsToValidate } = useContext(GroupFormContext);

  // This is used to prevent the flickering that happens during upload validation
  const { isFetching: uploadIsFetching } = useExcelUpload();

  const [selectedQuantity, setSelectedQuantity] = useState(
    presetValue || NO_INPUT_QUANTITY,
  );
  const [isInInitialState, setIsInInitialState] = useState(presetValue === "");
  const [errorType, setErrorType] = useState(ERROR_TYPE.NONE);
  const [stockDeficiencyMsg, setStockDeficiencyMsg] = useState("");

  const sizeValueIsInvalid =
    isSizedGood && (!sizeName || sizeName === NO_INPUT_SIZE);

  const lowStockMsg = isSizedGood
    ? t("groups-and-teams.field-limited-sized-good-stock")
        .replace("{size}", sizeName!)
        .replace("{total_stock}", totalStock.toString())
    : t("groups-and-teams.field-limited-hard-good-stock").replace(
        "{total_stock}",
        totalStock.toString(),
      );

  const remainingStock = useMemo(() => {
    const otherTeamStockTotal = groupsAndTeamsMembers
      .filter((member) => member.id !== entryId)
      .reduce((total, member) => (total += member.qty), 0);
    return Math.max(totalStock - otherTeamStockTotal, 0);
  }, [totalStock, groupsAndTeamsMembers, entryId]);

  const handleBlur = () => {
    if (sizeValueIsInvalid) handleSizeDropdownError();
    else if (!selectedQuantity && errorType !== ERROR_TYPE.INVALID)
      setErrorType(ERROR_TYPE.QUANTITY_REQUIRED);
  };

  const handleQuantityChange = (newQuantityValue: string) => {
    if (sizeValueIsInvalid) {
      handleSizeDropdownError();
      return;
    }

    if (isInInitialState) setIsInInitialState(false);
    setSelectedQuantity(newQuantityValue);

    const isInputValid = validateQuantityValue(
      dropdownValues,
      fieldDefinition.validationPattern,
      newQuantityValue,
      remainingStock,
    );
    onDropdownChange(newQuantityValue, fieldDefinition.key, isInputValid);
  };

  useEffect(
    () => {
      if (selectedQuantity && selectedQuantity !== NO_INPUT_QUANTITY) {
        const isInputInSelectionOptions =
          dropdownValues.includes(selectedQuantity) ||
          selectedQuantity === NO_INPUT_QUANTITY;
        const isInputOverRemainingStock =
          parseInt(selectedQuantity) > remainingStock;
        let newErrorType = ERROR_TYPE.NONE;

        if (!isInputInSelectionOptions) newErrorType = ERROR_TYPE.INVALID;
        if (isInputOverRemainingStock) {
          setStockDeficiencyMsg(
            totalStock === 0
              ? t("groups-and-teams.size-out-of-stock")
              : lowStockMsg,
          );
          newErrorType = ERROR_TYPE.INSUFFICIENT_STOCK;
        } else setStockDeficiencyMsg("");

        if (newErrorType !== errorType) setErrorType(newErrorType);

        // if (isInputValid) onDropdownChange(selectedQuantity, fieldDefinition.key, true);
        // else updateTeammateValidity(entryId, false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalStock, selectedQuantity, remainingStock],
  );

  useEffect(() => {
    if (triggerValidation && sizeValueIsInvalid) handleSizeDropdownError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValidation, selectedQuantity, sizeName]);

  useEffect(() => {
    if (
      (!selectedQuantity || selectedQuantity === NO_INPUT_QUANTITY) &&
      !sizeValueIsInvalid &&
      memberFormsToValidate.includes(entryId)
    ) {
      if (totalStock === 0) setErrorType(ERROR_TYPE.INSUFFICIENT_STOCK);
      else setErrorType(ERROR_TYPE.QUANTITY_REQUIRED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberFormsToValidate]);

  return (
    <FormField
      data-testid="teams-details-form-panel-quantity-dropdown"
      className={`teams-details-form-field teams-details-quantity-field${isSizedGood ? "-size" : "-hard"}`}
      marginRight={{
        sm: groupsAndTeamsMembers.length > 1 ? "between-actions" : 0,
      }}
    >
      <FormInputGroup>
        <DropdownWithFloatingLabel
          style={{
            width: "100%",
          }}
        >
          <Dropdown
            fullWidth
            autoFocus={autoFocus}
            name={fieldDefinition.key}
            aria-required={"true"}
            value={selectedQuantity}
            onChange={(event: any) => handleQuantityChange(event.target.value)}
            disabled={disabled}
            onBlur={handleBlur}
          >
            <DropdownOption disabled value={NO_INPUT_QUANTITY}>
              {t("form-placeholder.dropdown-choose")}
            </DropdownOption>
            {!dropdownValues.includes(selectedQuantity) &&
              selectedQuantity !== NO_INPUT_QUANTITY && (
                <DropdownOption disabled value={selectedQuantity}>
                  {selectedQuantity}
                </DropdownOption>
              )}
            {dropdownValues.map((quantitySelectionOption, index) => {
              return (
                <DropdownOption
                  disabled={parseInt(quantitySelectionOption) > totalStock}
                  key={`quantity-${quantitySelectionOption}-${index}`}
                  value={quantitySelectionOption}
                >
                  {quantitySelectionOption}
                </DropdownOption>
              );
            })}
          </Dropdown>
          <DropdownFloatingLabel>
            {`${fieldDefinition.name}`}
            <span aria-hidden="true">*</span>
            <VisuallyHidden>
              t("groups-and-teams.required-description")
            </VisuallyHidden>
          </DropdownFloatingLabel>
        </DropdownWithFloatingLabel>
        <FlexBox
          className="teams-details-quantity-warnings"
          flexDirection="column"
          // alignItems="flex-end"
        >
          {!uploadIsFetching &&
            isLowStock &&
            errorType !== ERROR_TYPE.INSUFFICIENT_STOCK && (
              <FormHelper mt={2}>{lowStockMsg}</FormHelper>
            )}
          {!uploadIsFetching && errorType !== ERROR_TYPE.NONE && (
            <ErrorMessage
              type={errorType}
              lowStockMessage={stockDeficiencyMsg}
            />
          )}
        </FlexBox>
      </FormInputGroup>
    </FormField>
  );
};
