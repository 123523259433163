import React from "react";
import { useAlertState } from "./contexts/AlertContext";
import { AlertBox, AlertBoxDismissButton, Box } from "@vp/swan";

const AlertCollection: React.FC<{}> = () => {
  const {
    error,
    warning,
    success,
    pricingError,
    setError,
    setWarning,
    setSuccess,
  } = useAlertState();

  return (
    <Box className="alert-collection" marginY={6}>
      {error && (
        <AlertBox
          skin="error"
          onRequestDismiss={() => setError("")}
          marginX="auto"
          marginBottom={4}
        >
          {error}
          <AlertBoxDismissButton accessibleText="Dismiss alert" />
        </AlertBox>
      )}
      {warning && (
        <AlertBox
          skin="warning"
          onRequestDismiss={() => setWarning("")}
          marginX="auto"
          marginBottom={4}
        >
          {warning}
          <AlertBoxDismissButton accessibleText="Dismiss alert" />
        </AlertBox>
      )}
      {success && (
        <AlertBox
          skin="success"
          onRequestDismiss={() => setSuccess("")}
          marginX="auto"
          marginBottom={4}
        >
          {success}
          <AlertBoxDismissButton accessibleText="Dismiss alert" />
        </AlertBox>
      )}
      {pricingError && (
        <AlertBox skin="error" marginX="auto">
          {pricingError}
        </AlertBox>
      )}
    </Box>
  );
};

export default AlertCollection;
