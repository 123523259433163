import { v4 as uuidv4 } from "uuid";
import { GroupsAndTeamsMember, Placeholder } from "../types/GroupsAndTeams";
import { NO_INPUT_SIZE } from "../commons/constants";
import qs from "qs";
import { getCulture } from "./LocalizationHelpers";
import { SizeData } from "../types/TeamDetailsConfig";

const UPLOAD_ENABLED_LOCALES =
  process.env.REACT_APP_UPLOAD_ENABLED_LOCALES!.split(",");

export const buildGroupsAndTeamMembersFromUploadData = (params: {
  entityCollection: Record<string, any>[];
  teamsPlaceholders: Placeholder[];
  availableSizes: SizeData[];
}): GroupsAndTeamsMember[] => {
  const { entityCollection, teamsPlaceholders, availableSizes } = params;

  return entityCollection.map((entity) => ({
    id: getId(),
    qty: parseInt(entity.quantity) || 0,
    selectedOptions:
      availableSizes.length > 0
        ? { Size: mapSize(entity.size, availableSizes) }
        : {},
    placeholderValues: getPlaceholderValues(entity, teamsPlaceholders),
    documentUrl: "",
    previewUrl: "",
    previewUrls: [],
    isNonPersonalized: false,
    shouldShowWarningForEmptyFields: true,
  })) as GroupsAndTeamsMember[];
};

export const shouldAllowUploadFlow = (): boolean => {
  const culture = getCulture();
  return (
    UPLOAD_ENABLED_LOCALES.includes(culture.toLowerCase()) ||
    doesEnableUploadQueryParamExist()
  );
};

const doesEnableUploadQueryParamExist = (): boolean => {
  const url = encodeURI(window.location.search.substring(1));
  const { enableUpload } = qs.parse(url);
  return !!enableUpload;
};

const getId = (): string => {
  return `${uuidv4()}_temp`;
};

export const removeUploadDataCaseSensitivity = (
  uploadedDataObjects: Record<string, any>[],
) => {
  return uploadedDataObjects.map((entity: Record<string, any>) => {
    let newEntity: Record<string, any> = {};
    Object.entries(entity).forEach(([key, value]) => {
      newEntity[key.toLowerCase()] = value;
    });
    return newEntity;
  });
};

const getPlaceholderValues = (
  entity: Record<string, any>,
  teamsPlaceholders: Placeholder[],
): any => {
  const placeholderValues: Record<string, any> = {};

  teamsPlaceholders.forEach((placeholder) => {
    const propName = placeholder.name.toLowerCase();
    if (entity.hasOwnProperty(propName))
      placeholderValues[placeholder.key] = entity[propName];
    else placeholderValues[placeholder.key] = "";
  });

  return placeholderValues;
};

export const mapSize = (entitySize: string, availableSizes: SizeData[]) => {
  if (availableSizes.length === 0 || !entitySize) return NO_INPUT_SIZE;
  const mappedSize = availableSizes.find(
    (size) => size.name.toLowerCase() === entitySize.toLowerCase(),
  );
  return mappedSize ? mappedSize.key : entitySize;
};
