import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Typography,
  Card,
  StandardForm,
  Icon,
  FlexBox,
  useScreenClass,
  Box,
} from "@vp/swan";
import type { DynamicSizedQuantities } from "@vp/lat-react-component-library";
import type { FieldDefinition } from "../../../../types/GroupsAndTeams";
import {
  SizeDropdownField,
  PlaceholderField,
  QuantityField,
} from "./formFields";
import {
  GroupsAndTeamsContext,
  SIZE_KEY,
  QUANTITY_KEY,
} from "../../context/GroupsAndTeamsContext";
import { DeleteTeammateModalWrapper } from "./DeleteTeammateModalWrapper";
import { useQuantity } from "../../../root/contexts/QuantityContext";
import { useAlertState } from "../../../root/contexts/AlertContext";
import type { InventoryData } from "../../../../types/Inventory";
import type { SizeData } from "../../../../types/TeamDetailsConfig";
import {
  INFINITE_INVENTORY,
  HARD_GOOD_INVENTORY_KEY,
  NO_INPUT_SIZE,
} from "../../../../commons/constants";

type FormEntryPanelProps = {
  initialEntryData: Record<string, string>;
  initialEntryValidity: Record<string, boolean>;
  entryId: string;
  entryNumber: number;
  hasAddMemberButton: boolean;
  formFieldDefinitions: FieldDefinition[];
  onFocus?: () => void;
  onFormEntryChanged: (
    entryId: string,
    formData: Record<string, string>,
    isFormValid: boolean,
  ) => void;
  validateQuantityInput: boolean;
  isSizedGood: boolean;
  availableSizes: SizeData[];
  inventory: Record<string, InventoryData> | undefined;
  isNonPersonalizedPlaceholder: boolean;
  shouldShowWarningForEmptyFields: boolean;
};

export const FormEntryPanel: React.FC<FormEntryPanelProps> = ({
  initialEntryData,
  initialEntryValidity,
  entryId,
  entryNumber,
  hasAddMemberButton,
  formFieldDefinitions,
  onFocus,
  onFormEntryChanged,
  validateQuantityInput = false,
  isSizedGood,
  availableSizes,
  inventory,
  isNonPersonalizedPlaceholder,
  shouldShowWarningForEmptyFields,
}) => {
  const { t } = useTranslation("translation");
  const { teamsQuantity, updateQuantity } = useQuantity();
  const { groupsAndTeamsMembers, isUpdatingTeam, isYsdDesign } = useContext(
    GroupsAndTeamsContext,
  );
  const { clearAlerts } = useAlertState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [teammateValidity, setTeammateValidity] =
    useState(initialEntryValidity);
  const [teammateValues, setTeammateValues] = useState(initialEntryData);

  const [prevSelectedSize, setPrevSelectedSize] = useState(
    isSizedGood ? initialEntryData.size : HARD_GOOD_INVENTORY_KEY,
  );
  const [prevSelectedQuantity, setPrevSelectedQuantity] = useState(
    parseInt(initialEntryData.qty) || 0,
  );
  const [sizeName, setSizeName] = useState<string | undefined>(undefined);
  const [totalStock, setTotalStock] = useState(INFINITE_INVENTORY);
  const [isLowStock, setIsLowStock] = useState(false);
  const [curSizeQuantity, setCurSizeQuantity] = useState(0);
  const isMobileView = useScreenClass() === "xs";

  useEffect(() => {
    onFormEntryChanged(
      entryId,
      teammateValues,
      Object.keys(teammateValidity).every((key) => teammateValidity[key]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teammateValidity]);

  useEffect(() => {
    if (prevSelectedSize && inventory) {
      // Ensure the selected size exists (necessary with inputs coming from uploads instead of input field)
      if (inventory[prevSelectedSize]) {
        if (isSizedGood) setSizeName(inventory[prevSelectedSize].sizeName);
        setTotalStock(inventory[prevSelectedSize].numAvailable);
        setIsLowStock(inventory[prevSelectedSize].lowStock);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevSelectedSize, inventory]);

  useEffect(() => {
    if (isSizedGood && prevSelectedSize) {
      setCurSizeQuantity(
        (teamsQuantity as DynamicSizedQuantities)[prevSelectedSize] || 0,
      );
    } else if (!isSizedGood) {
      setCurSizeQuantity(teamsQuantity as number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevSelectedSize, teamsQuantity]);

  const updateTeammateValidity = (key: string, validityData: boolean) => {
    // maybe CAN check status before update
    setTeammateValidity((prevValidity) => ({
      ...prevValidity,
      [key]: validityData,
    }));
  };

  const updateTeammateValues = (key: string, valueData: string) => {
    setTeammateValues((prevValues) => ({
      ...prevValues,
      [key]: valueData,
    }));
  };

  const onPlaceholderChange = (
    value: string,
    key: string,
    isValid: boolean,
  ) => {
    updateTeammateValues(key, value);
    updateTeammateValidity(key, isValid);
  };

  const onSizeDropdownChange = (
    curSelectedSize: string,
    key: string,
    isValid: boolean,
  ) => {
    const newTeamsQuantity = { ...(teamsQuantity as DynamicSizedQuantities) };
    newTeamsQuantity[prevSelectedSize] -= prevSelectedQuantity;
    if (newTeamsQuantity[prevSelectedSize] === 0) {
      delete newTeamsQuantity[prevSelectedSize];
    }
    if (!newTeamsQuantity[curSelectedSize]) {
      newTeamsQuantity[curSelectedSize] = 0;
    }
    newTeamsQuantity[curSelectedSize] += prevSelectedQuantity;
    updateQuantity(newTeamsQuantity);
    setPrevSelectedSize(curSelectedSize);
    updateTeammateValues(key, curSelectedSize);
    updateTeammateValidity(key, isValid);
  };

  const onQuantityChange = (value: string, key: string, isValid: boolean) => {
    const curQuantity = parseInt(value) || 0;
    if (isSizedGood) {
      const newTeamsQuantity = { ...(teamsQuantity as DynamicSizedQuantities) };
      if (!newTeamsQuantity[prevSelectedSize]) {
        newTeamsQuantity[prevSelectedSize] = 0;
      }
      newTeamsQuantity[prevSelectedSize] += curQuantity - prevSelectedQuantity;
      updateQuantity(newTeamsQuantity);
    } else {
      let newTeamsQuantity = teamsQuantity as number;
      newTeamsQuantity += curQuantity - prevSelectedQuantity;
      updateQuantity(newTeamsQuantity);
    }
    clearAlerts();
    setPrevSelectedQuantity(curQuantity);

    updateTeammateValues(key, value);
    updateTeammateValidity(key, isValid);
  };

  const forceSizeDropdownShowNoSizeError = () => {
    setPrevSelectedSize(NO_INPUT_SIZE);
  };

  const getEntryHeaderAndNumber = (): string => {
    return `${entryNumber + 1}.`;
  };

  const getEntryHeaderAndNumberMobile = (): string => {
    const baseText = isYsdDesign
      ? t("groups-and-teams.teammate-number")
      : t("groups-and-teams.person-number");
    return baseText.replace("{i}", (entryNumber + 1).toString());
  };

  const deleteIconAlt = t(
    `groups-and-teams.${
      isYsdDesign ? "remove-specific-teammate" : "remove-specific-person"
    }`,
  ).replace("{i}", (entryNumber + 1).toString());

  return (
    <>
      <Card
        className="teams-details-form-panel"
        marginRight={{ xs: 3 }}
        paddingX={0}
        paddingTop={0}
        paddingBottom={0}
        id={entryId}
        data-testid="teams-details-form-panel"
      >
        {isMobileView && (
          <FlexBox
            justifyContent="space-between"
            alignItems="center"
            mb="to-actions"
          >
            <Typography fontSkin="title-subsection">
              {getEntryHeaderAndNumberMobile()}
            </Typography>
            {groupsAndTeamsMembers.length !== 1 && (
              <Button
                buttonShape="round"
                onClick={() => setIsDeleteModalOpen(true)}
                disabled={isUpdatingTeam}
              >
                <Icon alt={deleteIconAlt} iconType="delete" skin="standard" />
              </Button>
            )}
          </FlexBox>
        )}
        <StandardForm className="teams-details-form-entry" marginBottom={0}>
          {!isMobileView && (
            <Typography
              fontSkin="body-standard-bold"
              className="teams-details-entry-number"
              paddingRight={3}
            >
              {getEntryHeaderAndNumber()}
            </Typography>
          )}
          {formFieldDefinitions.map((field, index) => {
            switch (field.key) {
              case SIZE_KEY:
                return (
                  <SizeDropdownField
                    key={SIZE_KEY}
                    autoFocus={index === 0 && hasAddMemberButton}
                    fieldDefinition={field}
                    defaultValue={prevSelectedSize}
                    disabled={isUpdatingTeam}
                    onDropdownChange={(curSelectedSize, key, isValid) =>
                      onSizeDropdownChange(curSelectedSize, key, isValid)
                    }
                    availableSizes={availableSizes}
                    inventory={inventory}
                  />
                );
              case QUANTITY_KEY:
                return (
                  <Box
                    key={QUANTITY_KEY}
                    className="teams-details-quantity-wrapper"
                    pr={groupsAndTeamsMembers.length === 1 ? 2 : 0}
                  >
                    <QuantityField
                      key={QUANTITY_KEY}
                      autoFocus={index === 0 && hasAddMemberButton}
                      onFocus={onFocus}
                      initialEntryData={initialEntryData}
                      fieldDefinition={field}
                      disabled={isUpdatingTeam}
                      onValueChange={(
                        currentQuantity: string | number,
                        key,
                        isValid,
                      ) =>
                        onQuantityChange(
                          currentQuantity.toString(),
                          key,
                          isValid,
                        )
                      }
                      updateTeammateValidity={(key, validityData) =>
                        updateTeammateValidity(key, validityData)
                      }
                      entryId={entryId}
                      validateQuantityInput={validateQuantityInput}
                      isSizedGood={isSizedGood}
                      sizeName={sizeName ?? prevSelectedSize}
                      totalStock={totalStock}
                      isLowStock={isLowStock}
                      curSizeQuantity={curSizeQuantity}
                      handleSizeDropdownError={forceSizeDropdownShowNoSizeError}
                    />
                  </Box>
                );

              default:
                return (
                  <PlaceholderField
                    entryId={entryId}
                    key={field.key}
                    autoFocus={index === 0 && hasAddMemberButton}
                    fieldDefinition={field}
                    defaultValue={initialEntryData[field.key] || ""}
                    disabled={isUpdatingTeam}
                    onFocus={onFocus}
                    onPlaceholderChange={(value, key, isValid) =>
                      onPlaceholderChange(value, key, isValid)
                    }
                    scrollId={
                      index === 0 ? entryId + "-firstFormField" : undefined
                    }
                    isNonPersonalized={isNonPersonalizedPlaceholder}
                    shouldShowWarningForEmptyFields={
                      shouldShowWarningForEmptyFields
                    }
                  />
                );
            }
          })}
          {!isMobileView && groupsAndTeamsMembers.length !== 1 && (
            <Box pr={2}>
              <Button
                buttonShape="round"
                onClick={() => setIsDeleteModalOpen(true)}
                disabled={isUpdatingTeam}
              >
                <Icon alt={deleteIconAlt} iconType="delete" skin="standard" />
              </Button>
            </Box>
          )}
        </StandardForm>
      </Card>
      {/** Note: delete errors will fail silently for the customer on the team details page */}
      <DeleteTeammateModalWrapper
        isOpen={isDeleteModalOpen}
        onCloseModal={() => setIsDeleteModalOpen(false)}
        memberToDelete={entryId}
        raiseErrorToCustomerOnFailure={false}
      />
    </>
  );
};
